.react-calendar__navigation {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  background-color: white;
  color: white;
}
.react-calendar__navigation:dark {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  background-color: black;
  color: white;
}
.react-calendar__navigation button span {
  padding: 5px;
  font-weight: 700;
  font-size: 1.5rem;
  color: #fafafa;
}
.react-calendar__navigation__arrow {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #fafafa;
  border-radius: 3px;
  border: 1px solid black;
}
.react-calendar__navigation__label {
  max-width: 70%;
}
.react-calendar__month-view__weekdays {
  padding: 15px 0px;
  background-color: #fafafa;
}
.react-calendar__month-view__weekdays__weekday {
  color: #0e0e0e;
  font-size: 1rem;
  display: flex;
  justify-content: center;
}
.react-calendar__month-view__weekdays__weekday abbr {
  color: #0e0e0e;
  font-weight: 500;
  text-decoration: none;
}

.react-calendar__viewContainer {
  flex: 2;
  background-color: #fff;
}
.react-calendar__tile {
  transition: all;
  transition-duration: 400ms;
  background-color: #0e0e0e;
  color: white;
}
.react-calendar__tile:hover {
  color: #c78c4e;
}

.react-calendar__tile--active {
  background-color: #c78c4e;
  color: white;
}
.react-calendar__tile--active:hover {
  color: white;
}

.react-calendar__tile:disabled {
  background-color: #e7e7e7;
  opacity: 0.8;
  color: #666666;
}

@media (max-width: 640px) {
  .react-calendar__navigation__arrow {
    padding: 3px 10px;
  }
  .react-calendar__navigation button span {
    font-size: 1rem;
  }
  .react-calendar__month-view__weekdays__weekday {
    font-size: 0.7rem;
  }
}
